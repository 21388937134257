import { render, staticRenderFns } from "./NotificationFiles.vue?vue&type=template&id=bd14e8d6&scoped=true"
import script from "./NotificationFiles.vue?vue&type=script&lang=js"
export * from "./NotificationFiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd14e8d6",
  null
  
)

export default component.exports